import React, { useState, useEffect } from 'react'
import Collapsible from 'react-collapsible';
import { useQueryParam, StringParam } from 'use-query-params'
import { graphql } from 'gatsby'
import Filters from '../components/filters'
import Layout from '../components/layout'
import PromptButton from '../components/promptButton'
import Message from '../components/message'
import DigestCard from '../components/digestCard'
import styles from '../styles/digests.module.css'
import IconDigest from '../assets/icon-digest.svg'

const WeatherReport = ({ data }) => {

	const {allAirtableDigests:digests} = data
    const [currentTag] = useQueryParam('tag', StringParam)
    const [scroll, setScroll] = useState(0)
    const [search, setSearch] = useState('')
    const tags = [...new Set(digests?.nodes.map(resource => resource.data.Topics ?? []).flat())].sort()
    let filteredDigests = digests?.nodes
        .filter(digest => currentTag === undefined || digest.data.Topics?.includes(currentTag))
			if (filteredDigests !== undefined){
				filteredDigests = filteredDigests.sort((a, b) => b.data.Date_updated - a.data.Date_updated )
					.filter(digest => search === '' || digest.data.Title.toLowerCase().includes(search.toLocaleLowerCase()) || digest.data.News.some(news => news.data.Title.toLowerCase().includes(search.toLocaleLowerCase()))) ?? []

			} else {
				filteredDigests = []
			}

	const handleSubscribe = () => {
        window.open('https://airtable.com/shrwGCmLkpNbpd3rp', '_blank')
	}
	const handleDigest = () => {
		window.open('/subscribe', '_blank')
	}

	useEffect(() => {
        window.scrollTo(0, scroll)
	})

    return (
		<Layout>
			<div className={styles.header}>
                <h1>Weather report</h1>
				<span  ><PromptButton primary={true} handleClick={handleDigest} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'   Subscribe to a Digest    '}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</PromptButton>
				{/*<PromptButton primary={true} handleClick={handleSubscribe} >     {'Subscribe to weather report'}</PromptButton> */}
				</span>
            </div>


			<div target={'Nextstrain'} className={styles.nextStrain}>
				<iframe title={'Nextstrain'} width={'100%'} height={'420px'} frameBorder={0} src={'https://nextstrain.org/ncov/global?onlyPanels&sidebar=closed&c=emerging_lineage&dmin=2021-08-31'}></iframe>
			</div>

			<div target={'ROSALIND TRACKER'}  className={styles.roslind}>
				<div>Rosalind Tracker</div>
				<a title={'ROSALIND TRACKER'} className={styles.roslindlink} target='_blank' href={'https://tracker.rosalind.bio/dxm/tracker-dashboard/?number-of-days=60&graph-display=count'}> 60 Days (Opens in New Tab) </a>

			</div>
			<Filters path={`/weatherReport`} tags={tags} currentTag={currentTag} search={search} handleClick={() => setScroll(window.scrollY)} handleSearch={value => setSearch(value)}/>
			{
				filteredDigests.length > 0? (
					<div className={styles.digests}>
						{
							filteredDigests.map(digest => {
								const {data, id} = digest

								return (
									<DigestCard key={id} title={data.Title} image={data.Image?.[0].url} topics={data.Topics} currentTag={currentTag} publications={data.News} dateUpdated={data.Date_updated} slug={data.Slug}/>
								)
							})
						}
					</div>
				) : (
					<Message Icon={IconDigest} content={'Digests not found'}/>
				)
			}
		</Layout>
	)
}

export const query = graphql`
    query {
		allAirtableDigests(sort: {fields: data___Date_updated, order: DESC}) {
			nodes {
				id
				data {
					Date_updated
					Image {
						url
					}
					News {
						data {
							Title
							Source
							Date
							Source_type
              Author
              Journal
							HideFromNetwork
						}
					}
					Title
					Topics
					Slug
				}
			}
		}
    }
`

export default WeatherReport
