import React from 'react'
import { Link } from 'gatsby'
import { dateFormat } from '../helpers/formats'
import PublicationList from './publicationList'
import Pill from '../components/pill'
import styles from '../styles/digestCard.module.css'

const DigestCard = ({dateUpdated, slug, title, image, topics, currentTopic, publications}) => {

    if(publications) {
        publications.forEach(publication => {
            publication.data.Date = new Date(publication.data.Date)
        })

        publications =  publications.sort((a,b) => new Date( b.data.Date).getTime() - new Date( a.data.Date).getTime()) //.reverse()

    }

    return (
        <div className={styles.digestCard}>
            <div className={styles.content}>
                {
                    image && (<div className={styles.image} style={{backgroundImage: `url(${image})`}}/>)
                }
                <div className={styles.timestamp}>{dateFormat(new Date(dateUpdated))}</div>
                <Link to={`/weatherReport/${slug}`}>
                    <h2>{title}</h2>
                </Link>
                <div className={styles.topics}>
                    {
                        topics?.sort((a, b) => a.localeCompare(b)).map((topic, i) => (
                            <Pill key={i} link={`/weatherReport?topic=${topic}`} label={topic} disabled={topic === currentTopic}/>
                        ))
                    }
                </div>
                <PublicationList publications={publications} max={3} readMore={`/weatherReport/${slug}`}/>
            </div>
        </div>
    )
}

export default DigestCard
